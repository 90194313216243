import React, { Component } from 'react';
import { navigate, Link } from 'gatsby';
import Select, { components } from 'react-select';

import { toDanishCurrency } from '../utilities/helpers';
import { ThemeProvider, ThemeContext } from '../context/ThemeProvider';
import listingService from '../services/ListingService';

import headerLogo from '../images/logo_footer.svg';
import loader from '../images/page-loader.svg';

import '../styles/checkout.scss';
import SEO from '../components/seo';
import Axios from 'axios';

import { countryCodeAndIso } from '../utilities/helpers';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();


export class Inquire extends Component {
    constructor() {
        super();

        this.state = {
            data: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                message: '',
                acceptPrivacy: false,
                subscribeNewsletter: false,
                nameOnCard: ''
            },
            stripe: false,
            loading: true,
            couponCode: '',
            showCouponField: false,
            isSubmitting: false,
            errorMessage: '',
            phoneCode: '',
            error: {},
            menuIsOpen: false,
            countryValue: ''
        }
    }

    componentDidMount() {
        if (typeof this.props.location.state === "undefined" || this.props.location.state === null) {
            navigate('/');
        }
        else {
            this.init();
        }
    }

    init() {
        // stripePromise = loadStripe('pk_live_P0FSIEtbwU1GSvgvEM3DYuUZ');
        setTimeout(() => {
            this.setState({ stripe: true});
        }, 1000);
    }

    handleChange = (e) => {
        const { name, value } = e.currentTarget;
        const { data } = this.state;
        
        if (e.currentTarget.name === 'phone') {
            data[name] = value.replace(/\D/g,'');
        }
        else {
            data[name] = value;
        }

        this.setState({ data });
    }

    toggleCoupon = () => {
        const { showCouponField } = this.state;
        this.setState({ showCouponField: !showCouponField });
    }
    
    onCouponChange = (e) => {
        const { value } = e.currentTarget;

        if (value.length >= 2) {
            this.setState({ couponCode: value });
            // window.clearTimeout(this.couponTimeout);

            // this.couponTimeout = setTimeout(async () => {
            //     // API all for coupon
            // }, 1500);
        }
        else {
            this.setState({ couponCode: value });
        }
    }

    handleChangeCountry = (e) => {
        const phoneValue = e.value.split('|')[1];
        this.setState({ phoneCode: phoneValue });
    }

    onPayment = async ({ token, reservationId, guestId }) => {
        source.cancel();
        source = CancelToken.source();

        const { listingInfo, listingId, cleaningFee } = this.props.location.state;
        let { data } = this.state;

        let payload = {
            listingId,
            reservationId,
            guestId,
            cardToken: token,
            fareAccomodation: listingInfo.totalPrice + cleaningFee,
            currency: 'DKK',
            message: data.message,
            firstName: data.firstName,
            lastName: data.lastName
        };

        let paymentResponse = await listingService.addPaymentToReservation(payload, source.token);

        if (paymentResponse.success) {
            let reservationPayload = {
                reservationId,
                subscribeNewsletter: data.subscribeNewsletter,
                hasAutoPayment: paymentResponse.data.hasAutoPayment
            };
            let reservation = await listingService.refreshReservationStatus(reservationPayload, source.token);
    
            if (reservation.success) {
                if (reservation.data.isConfirmed) {
                    return reservation.data;
                }
                else {
                    alert(reservation.errorMessage);
                }
            }
            else {
                alert(reservation.errorMessage);
            }
        }
        else {
            alert(paymentResponse.errorMessage);
        }

        this.setState({ isSubmitting: false }, () => {
            document.body.style.overflowY = '';
        });
    }

    logError = (name, errorDetail) => {
        const { listingInfo, listingId } = this.props.location.state;
        let message = {
            errorDetails: JSON.stringify(errorDetail),
            listingDetails: {
                listingId,
                listingInfo
            }
        }

        listingService.logError({ name, message }, source.token);
    }

    onPaymentSuccess = () => {
        const { data } = this.state;
        const { checkin, checkout, guests, address } = this.props.location.state;
        
        this.setState({ isSubmitting: false }, () => {
            document.body.style.overflowY = '';

            navigate('/checkout/success', 
                {
                    replace: true,
                    state: {
                        guestName: `${data.firstName} ${data.lastName}`,
                        propertyAddress: address.fullAddress,
                        guestCount: guests,
                        checkinDate: checkin,
                        checkoutDate: checkout
                    }
                }
            );
        });
    }

    onReservationSuccess = () => {
        const { data } = this.state;
        const { checkin, checkout, guests, address } = this.props.location.state;
        
        this.setState({ isSubmitting: false }, () => {
            document.body.style.overflowY = '';

            navigate('/inquiry/success', 
                {
                    replace: true,
                    state: {
                        guestName: `${data.firstName} ${data.lastName}`,
                        propertyAddress: address.fullAddress,
                        guestCount: guests,
                        checkinDate: checkin,
                        checkoutDate: checkout
                    }
                }
            );
        });
    }


    verifyReservation = () => {
        let { data, errorMessage, error } = this.state;
        let hasError = false;
        let isAcceptedPrivacy = true;
        error = {};
        
        if (!data.firstName) {
            hasError = true;
            error.firstName = "Please fill in all required fields.";
        }
        if (!data.lastName) {
            hasError = true;
            error.lastName = "Please fill in all required fields.";
        }
        if (!data.email) {
            hasError = true;
            error.email = "Please fill in all required fields.";
        }
        if (!data.phone) {
            hasError = true;
            error.phone = "Please fill in all required fields.";
        }
        if (!data.acceptPrivacy) {
            isAcceptedPrivacy = false;
        }

        if (hasError && !isAcceptedPrivacy) {
            errorMessage = "Please fill in all required fields and make sure that you have agreed to the Privacy Policy.";            
            this.setState({ errorMessage, error });
            return false;
        }
        else if (hasError) {
            errorMessage = "Please fill in all required fields.";            
            this.setState({ errorMessage, error });
            return false;
        }
        else if (!isAcceptedPrivacy) {
            errorMessage = "Please read and accept the Privacy Policy.";            
            this.setState({ errorMessage, error });
            return false;
        }
        else {
            this.setState({ error : {}, errorMessage: '' });
            return true;
        }
    }

    onReservation = async (cardToken) => {
        // API Call
        const { checkin, checkout, guests, listingInfo, listingId, cleaningFee } = this.props.location.state;
        let { data, phoneCode } = this.state;

        let param = {
            guestyReservation: {
                listingId: listingId,
                money: {
                    fareAccomodation: listingInfo.totalPrice + cleaningFee,
                    currency: 'DKK'
                },
                guest: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phone: '+' + phoneCode + data.phone
                },
                guestsCount: guests
            },
            checkInDate: checkin,
            checkOutDate: checkout,
            token:cardToken,
            // message: data.message,
            // subscribeNewsletter: data.subscribeNewsletter
        };

        this.setState({ isSubmitting: true }, () => {
            document.body.style.overflowY = 'hidden';
        });

        source.cancel();
        source = CancelToken.source();

        let reservationResponse = await listingService.createReservation(param, source.token);

        if (reservationResponse.success) {
            return {
                // customerId: reservationResponse.Data.customerId,
                // reservationId: reservationResponse.Data.reservationId,
                // guestId: reservationResponse.Data.guestId,
                success: reservationResponse.success,
                listingId
            }
        }
        else {
            alert(reservationResponse.errorMessage);
            this.setState({ isSubmitting: false }, () => {
                document.body.style.overflowY = '';
            });

            return null;
        }
    }
    
    onCreateInquiry = async () => {
        const { checkin, checkout, guests, listingInfo, listingId, cleaningFee } = this.props.location.state;
        let { data, phoneCode } = this.state;

        let param = {
            guestyReservation: {
                listingId: listingId,
                money: {
                    fareAccomodation: listingInfo.totalPrice + cleaningFee,
                    currency: 'DKK'
                },
                guest: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phone: '+' + phoneCode + data.phone
                },
                guestsCount: guests
            },
            checkInDate: checkin,
            checkOutDate: checkout,
            message: data.message,
            // subscribeNewsletter: data.subscribeNewsletter
        };

        this.setState({ isSubmitting: true }, () => {
            document.body.style.overflowY = 'hidden';
        });

        source.cancel();
        source = CancelToken.source();

        let reservationResponse = await listingService.createInquiry(param, source.token);

        if (reservationResponse.success) {
            return {
                // customerId: reservationResponse.Data.customerId,
                // reservationId: reservationResponse.Data.reservationId,
                // guestId: reservationResponse.Data.guestId,
                success: reservationResponse.success,
                listingId
            }
        }
        else {
            alert(reservationResponse.errorMessage);
            this.setState({ isSubmitting: false }, () => {
                document.body.style.overflowY = '';
            });

            return null;
        }
    }

    onSubmit = async (e) => {
        var isFormValid = this.verifyReservation();
        if (isFormValid) {
            var createReservationResponse = await this.onCreateInquiry();
            if(createReservationResponse.success) {
                this.onReservationSuccess();
            }
        }
    }

    render() {
        const { stripe, data, isSubmitting, errorMessage, error, menuIsOpen } = this.state;
        let { theme, seo } = this.props.pageContext;
        const { location } = this.props;

        if (!stripe) {
            return (
                <div className="listings-loader"><img src={loader} alt="Loading..." /></div>
            );
        }

        if (!seo) {
            seo = {};
            seo.Page = {
                Title: "Checkout"
            }
        }
        
        let checkin, checkout, guests, listingInfo, title, propertyType, cleaningFee, address;

        if (location && location.state) {
            checkin = location.state.checkin;
            checkout = location.state.checkout;
            guests = location.state.guests;
            listingInfo = location.state.listingInfo;
            title = location.state.title;
            propertyType = location.state.propertyType;
            cleaningFee = location.state.cleaningFee
            address = location.state.address;
        }

        let countryOptions = [];
        let countryIsoCodes = countryCodeAndIso();

        for (let index = 0; index < countryIsoCodes.length; index++) {
            countryOptions.push({
                value: index + "|" + countryIsoCodes[index].countrycode,
                label: countryIsoCodes[index].countrycode,
                flagIcon: countryIsoCodes[index].isocode.toLowerCase(),
            });
        }

        let links = [];

        links.push(<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/css/flag-icon.min.css" />);

        return (
            <React.Fragment>
                <SEO {...seo} links={links} />
                <ThemeProvider theme={theme}>
                    <div className="checkout">
                        {
                            isSubmitting && 
                            <div className="loader">
                                <img src={loader} alt="Loading..." />
                                <div>Processing...</div>
                            </div>
                        }
                        <div className="checkout__header-logo">
                            <Link to="/">
                                <img src={headerLogo} alt="logo" />
                            </Link>
                        </div>
                        <div className="checkout__booking-info">
                            <div className="checkout__booking-info__price-summary">
                                <div className="checkout__booking-info__title">{title}</div>
                                <ul>
                                    <li>
                                        <div>Location:</div>
                                        { address && <span>{address.city}, {address.country}</span>}
                                    </li>
                                    <li>
                                        <div>Guest:</div>
                                        <span>{guests}</span>
                                    </li>
                                    <li>
                                        <div>Type:</div>
                                        <span>{propertyType}</span>
                                    </li>
                                    <li>
                                        <div>Avg. per night:</div>
                                        { listingInfo && <span>{toDanishCurrency(listingInfo.avgPerNight)}</span>}
                                    </li>
                                    <li>
                                        <div>Check in:</div>
                                        <span>{checkin}</span>
                                    </li>
                                    <li>
                                        <div>{`${listingInfo.totalNights} night${listingInfo.totalNights > 1 ? 's': ''}:`} </div>
                                        { listingInfo && <span>{toDanishCurrency(listingInfo.totalPrice)}</span>}
                                    </li>
                                    <li>
                                        <div>Check out:</div>
                                        <span>{checkout}</span>
                                    </li>
                                    <li>
                                        <div>Cleaning fee:</div>
                                        <span>{toDanishCurrency(cleaningFee)}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="checkout__booking-info__price-total">
                                <div className="price-total-label">Total:</div>
                                { listingInfo && <div className="price-total">{toDanishCurrency(listingInfo.totalPrice + cleaningFee)}</div>}
                                <div className="cancellation-policy"><a href="/cancellation-policy" target="_blank">Cancellation Policy</a></div>
                            </div>
                        </div>
                        <div className="layout-row checkout__main-info" >
                            <div className="checkout__main-info__personal-info" style={{margin:`0 auto`}}>
                                <div className="personal-info-title">
                                    Personal Info
                                </div>
                                <div className="form">
                                    {errorMessage && <div className="notification notification--error">{errorMessage}</div>}
                                    <div className="form-inline">
                                        <div className="checkout-field">
                                            <span className="content-name">First Name*</span>
                                            <input type="text" name="firstName" className={`${error["firstName"] ? 'has-error' : ''}`} onChange={this.handleChange} value={data.firstName} />
                                        </div>
                                        <div className="checkout-field">
                                            <span className="content-name">Last Name*</span>
                                            <input type="text" name="lastName" className={`${error["lastName"] ? 'has-error' : ''}`} onChange={this.handleChange} value={data.lastName} />
                                        </div>
                                    </div>
                                    <div className="form-inline">
                                        <div className="checkout-field">
                                            <span className="content-name">Email*</span>
                                            <input type="email" name="email" className={`${error["email"] ? 'has-error' : ''}`} onChange={this.handleChange} value={data.email} />
                                        </div>
                                        <div className="checkout-field">
                                            <span className="content-name">Phone*</span>
                                            <div className="phone-field" style={{ position: 'relative'}}>
                                                <Select options={countryOptions} isSearchable={true} onChange={this.handleChangeCountry} className="country-select" classNamePrefix="country-select"
                                                    menuIsOpen={menuIsOpen}
                                                    onMenuOpen={() => {
                                                        let flag = document.getElementsByClassName('country-select__single-value')[0];
                                                        if (flag) {
                                                            flag.style.visibility = 'hidden';
                                                        }
                                                        
                                                        this.setState({ menuIsOpen: true })
                                                    }}
                                                    onMenuClose={() => {
                                                        let flag = document.getElementsByClassName('country-select__single-value')[0];
                                                        if (flag) {
                                                            flag.style.visibility = 'visible';
                                                        }

                                                        setTimeout(() => {
                                                            let valueContainer = document.getElementsByClassName('country-select__value-container')[0];
                                                            if (valueContainer) {
                                                                valueContainer.style.height = 'unset';
                                                            }
                                                        }, 100);
                                                
                                                        setTimeout(() => {
                                                            let valueContainer = document.getElementsByClassName('country-select__value-container')[0];
                                                            if (valueContainer) {
                                                                valueContainer.style.height = '100%';
                                                            }
                                                        }, 150);

                                                        this.setState({ menuIsOpen: false })
                                                    }}
                                                    defaultValue={countryOptions[0]}
                                                    components={{
                                                        Option: Option,
                                                        SingleValue: SingleValue
                                                    }}
                                                />
                                                <input type="number" name="phone" className={`${error["phone"] ? 'has-error' : ''}`} onChange={this.handleChange} value={data.phone} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkout-field" style={{ marginBottom: 20 }}>
                                        <span className="content-name">Message</span>
                                        <textarea className="checkout-field--message" type="text" name="message" rows={5} onChange={this.handleChange} value={data.message} />
                                    </div>
                                    <div style={{marginLeft: 10}}>
                                        <input type="checkbox" id="acceptPrivacy" name="acceptPrivacy" onChange={this.handleChange} value={data.acceptPrivacy} />
                                        <label htmlFor="acceptPrivacy" className="checkbox-label privacy-policy"><span>I have read and accept the <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</span></label>
                                    </div>
                                    <div style={{marginLeft: 10}}>
                                        <input type="checkbox" id="subscribeNewsletter" name="subscribeNewsletter" onChange={this.handleChange} value={data.subscribeNewsletter} />
                                        <label htmlFor="subscribeNewsletter" className="checkbox-label"><span>I am interested in receiving discounts, promotions and news about Dinesen Collection.</span></label>
                                    </div>
                                    <div>
                                        <button  className={`btn btn-primary`} type="button" onClick={this.onSubmit} >{"Send inquiry"}</button> 
                                    </div>
                                </div>
                            </div>
                            {/* <div className="checkout__main-info__billing-info">
                                <div className="billing-details-title">
                                    <div>Billing details</div>
                                    <div className="billing-info__supported-card-type">
                                        <ul>
                                            <li><img src={iconVisa} alt="Visa" /></li>
                                            <li><img src={iconMastercard} alt="Mastercard" /></li>
                                            <li><img src={iconMaestro} alt="Maestro" /></li>
                                            <li><img src={iconVisaElectron} alt="Visa Electron" /></li>
                                            <li><img src={iconAmex} alt="Amex" /></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="form">
                                    <Elements stripe={stripePromise}>
                                        <InjectedCheckoutForm data={data} onNameOnCardChange={this.handleChange}
                                            toggleCoupon={this.toggleCoupon} onCouponChange={this.onCouponChange} 
                                            showCouponField={showCouponField} couponCode={couponCode}
                                            onReservation={this.onReservation} onPayment={this.onPayment} verifyReservation={this.verifyReservation}
                                            onPaymentSuccess={this.onPaymentSuccess} logError={this.logError} />
                                    </Elements>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </ThemeProvider>
            </React.Fragment>
        )
    }
}


Inquire.contextType = ThemeContext;

const Option = props => (
    <components.Option {...props}>        
        <span className={`flag-icon flag-icon-${props.data.flagIcon}`} style={{display: 'inline-block', marginRight: '5px'}}></span>
        <span style={{display: 'inline-block'}}>{props.data.label}</span>
    </components.Option>
)

const SingleValue = props => (
    <components.SingleValue {...props}>        
        <span className={`flag-icon flag-icon-${props.data.flagIcon}`}></span>
    </components.SingleValue>
)

export default Inquire;
